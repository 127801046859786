body {
  margin: 0;
  background-color: #2b3e49;
}

/******************************************************
 * Enforce titles to readable
 * This options is not available directly in redos
*******************************************************/
h5 {
  color: #d7df23 !important;
  span {
    color: #fcfcfc !important;
  }
}
